import styled from 'styled-components';

const Post = styled.div`
margin-top: 20px;
line-height: 1.3em;
h1 {
    font-size: 1.5em;
    font-weight: 700;
    padding: 5px 0;
    margin-top: 30px;
    margin-bottom: 5px;
    width: 100%;
    border-bottom: 2px solid #ccc;
}
h2 {
    font-size: 1.2em;
    font-weight: 700;
    padding: 3px 0;
    margin-top: 30px;
    color: ${props => props.theme.colors.primaryAccent};
}
ul {
    list-style-type: disc;
    padding-inline-start: 30px;
    margin: 5px 0;
    li {
        padding: 5px 0 5px 10px;
    }
}
ol {
    list-style-type: decimal;  
    padding-inline-start: 30px;
    margin: 5px 0;
    li {
        padding: 5px 0 5px 10px;
    }
}
p {
    padding: 5px 0;
}
blockquote, em {
    font-style: italic;
}
a {
    font-weight: 700;
    color: ${props => props.theme.colors.secondaryAccent};
}
strong {
    font-weight: 700;
}
`;

export default Post;

// code {
//     display: block;
//     background: ${props => props.theme.colors.dark};
//     padding: 10px;
//     border-radius: 5px;
//     color: ${props => props.theme.colors.secondaryAccent};
//     font-family: "Courier New", Courier, monospace;
// }