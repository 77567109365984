import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from "gatsby";
import { ArrowLeftCircle, ArrowRightCircle } from 'styled-icons/feather';
import { DiscussionEmbed } from 'disqus-react';


import Layout from '../layouts/Layout/Layout';
import Post from '../styles/Post';
import Tag from '../components/Tag/Tag';

const BlogPostStyled = styled.div`
    width: 100%;
`;

const Title = styled.h2`
    font-size: 2.8em;
    font-weight: 700;
    color: ${props => props.theme.colors.primaryAccent};
    margin: 5px 0;
`;

const Date = styled.h3`
    font-size: 1em;
    font-style: italic;
`;

const NavButtons = styled.div`
    margin: 40px 0 80px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: ${props => props.theme.colors.primaryAccent};
    font-size: 1.3em;
    a {
        :hover {
        text-decoration: underline;
    }
    }    
`;


const blogPost = (props) => {
    const post = props.data.markdownRemark;
    const tagsArray = post.frontmatter.tags
    const { previous, next } = props.pageContext;

    const disqusShortname = "frontendstumbles";
    const disqusConfig = {
      identifier: post.id,
      title: post.frontmatter.title,
    };

    return (

        <Layout
            title={post.frontmatter.title}
            description={post.frontmatter.description}
            url={'https://www.frontendstumbles.com' + props.location.pathname.slice(0,-1)}
            image={post.frontmatter.image.publicURL}
            type='article'
        >

            <BlogPostStyled>
                <Date>
                    {post.frontmatter.date}
                </Date>
                <Title>
                    {post.frontmatter.title}
                </Title>
                {tagsArray.map((tag, index) => (
                    <Tag  key={index} slug={'/tags/' + tag}>{tag}</Tag>
                ))}
                <Post dangerouslySetInnerHTML={{ __html: post.html }} />

                <NavButtons>
                    <div>
                        {next && (
                            <Link to={next.fields.slug}>
                                <ArrowLeftCircle size='20'/>   Newer
                            </Link>
                        )}
                    </div>
                    <div>
                        {previous && (
                            <Link to={previous.fields.slug}>
                                Older   <ArrowRightCircle size='20'/>
                            </Link>
                        )}
                    </div>
                </NavButtons>

                <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
            </BlogPostStyled>
        </Layout>
    )
}


export default blogPost;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
        tags
        image {
            publicURL
        }
    }
    }
  }
`